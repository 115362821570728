<template>
  <!-- Table Container Card -->
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Search Game-->
          <b-col
            cols="12"
            md="4"
          />
          <!-- Category-->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="templateCategory"
                :options="allCategories"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_CATEGORY')"
                @input="fetchTemplates(templateCategory)"
              />
            </div>
          </b-col>
          <!-- Template-->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="template"
                :options="templates"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_TEMPLATE')"
                @input="fetchTemplatesCharity(template)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <section id="feather-icons">
      <div
        id="icons-container"
        class="d-flex flex-wrap"
      >
        <b-card
          v-for="charity in charities"
          :key="charity.ID"
          v-b-tooltip.hover.top="charity.name"
          class="icon-card cursor-pointer text-center mb-2 mx-50"
          @click="addorDeleteTemplateCharity(charity)"
        >
          <div class="icon-wrapper">
            <b-avatar
              rounded
              size="95"
              variant="light-primary"
            >
              <b-img
                :src="charity.logourl"
                alt="avatar img"
                width="90px"
                height="90px"
              /></b-avatar>
          </div>
          <b-card-text class="icon-name text-truncate mb-0 mt-1">
            <feather-icon
              v-if="charity.isGifted"
              class="text-success"
              icon="GiftIcon"
              size="24"
            />
          </b-card-text>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardText, VBTooltip, BImg,
  BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BCardText,
    vSelect,
    BImg,
    BAvatar,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      templateCategory: {
        ID: 0,
        name: 'Select Category',
        color: '',
        description: '',
      },
      charities: [],
      templates: [],
      templateCharities: [],
      template: '',
    }
  },
  computed: {
    ...mapState('template', ['allCategories', 'allTemplateCharity']),
    ...mapState('game', ['allCharities']),
  },
  created() {
    this.fetchAllCharities().then(() => {
      this.fetchAllCategories()
      this.charities = this.allCharities
    }).catch(() => {
      this.showErrorMessage()
    })
  },
  methods: {
    ...mapActions('game', ['fetchAllCharities', 'fetchAllGameCharities', 'createCompetitionCharity', 'deleteCompetitionCharity']),
    ...mapActions('template', ['fetchAllCategories', 'fetchAllTemplates', 'fetchAllTemplateCharity', 'createTemplateCharity', 'deleteTemplateCharity']),
    fetchTemplatesCharity(e) {
      this.templateCharities = []
      this.fetchAllTemplateCharity(e.ID).then(response => {
        if (response) {
          this.templateCharities = response
          this.mapTemplateCharity()
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    fetchTemplates(e) {
      this.templates = []
      const params = `?category_id=${e.ID}&type=game`
      this.fetchAllTemplates(params).then(response => {
        if (response) {
          this.templates = response
          this.charities = this.allCharities
          this.template = ''
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    addorDeleteTemplateCharity(charity) {
      if (charity.isGifted) {
        this.deleteTemplateCharity(charity.template_charity_id).then(() => {
          this.successMessage(this.$i18n.t('MESSAGE.CHARITY_DELETED'))
          this.fetchTemplatesCharity(this.template)
        })
      } else {
        const payload = { charity_id: charity.ID, category_id: this.templateCategory.ID, template_id: this.template.ID }
        this.createTemplateCharity(payload).then(() => {
          this.successMessage(this.$i18n.t('MESSAGE.CHARITY_CREATED'))
          this.fetchTemplatesCharity(this.template)
        })
      }
    },
    mapTemplateCharity() {
      try {
        this.charities = JSON.parse(JSON.stringify(this.allCharities))
        if (this.allTemplateCharity.length > 0) {
          this.allTemplateCharity.forEach(e => {
            const matchingTemplateCharity = this.charities.find(element => element.ID === e.charity_id)
            if (matchingTemplateCharity) {
              matchingTemplateCharity.template_charity_id = e.id
              matchingTemplateCharity.isGifted = true
            }
          })
        }
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
